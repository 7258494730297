import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/pages/Home.vue')
  },
  {
    path: '/foto',
    name: 'Foto',
    component: () => import('../views/pages/Foto.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
